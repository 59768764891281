<template>
    <tr>
        <td> {{ item.date }}</td>
        <td> {{ item.bill_number }}</td>
        <td class="text-right"> {{ item.total_amount }}</td>
        <td class="text-right">
          <div class="d-flex justify-content-end">
            <input
                v-model="item.vat_challan_amount"
                type="number" class="form-control text-right"
                style="width: 100px"
                :readonly="isDisabled"
            >
          </div>
        </td>
        <td class="text-right">
          <div class="d-flex justify-content-end">
            <input
                v-model="item.ait_challan_amount"
                type="number" class="form-control text-right"
                style="width: 100px"
                :readonly="isDisabled"
            >
          </div>
        </td>
        <td class="text-right">
          <div class="d-flex justify-content-end">
            <input
                v-model="item.discount_amount"
                type="number" class="form-control text-right"
                style="width: 100px"
                :readonly="isDisabled"
            >
          </div>
        </td>
        <td class="text-right"> {{ item.paid_amount }}</td>
        <td class="text-right"> {{ item.adjusted_amount }}</td>
        <td class="text-right"> {{ commaFormat(getDue) }}</td>
        <td class="text-right">
            <div class="d-flex justify-content-end">
                <input
                    v-model="item.payment"
                    @input="checkInputValidity"
                    type="number" class="form-control text-right"
                    style="width: 100px"
                    :readonly="isDisabled"
                >
            </div>
        </td>
    </tr>
</template>

<script setup>
import {computed} from "vue";
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
    item: Object,
    isDisabled: {
        type: Boolean,
        default: false
    }
})
const {commaFormat} = figureFormatter()

function checkInputValidity (e) {
    if(e.target.value > props.item.due) {
        props.item.payment = props.item.due
    }
}

const getDue = computed(() => {
  const vdsValue = props.item.vat_challan_amount ? props.item.vat_challan_amount : 0;
  const aitValue = props.item.ait_challan_amount ? props.item.ait_challan_amount : 0;
  const discountValue = props.item.discount_amount ? props.item.discount_amount : 0;
  return props.item.due = props.item.total_amount - props.item.adjusted_amount - (vdsValue + aitValue + discountValue + props.item.paid_amount);
})
</script>
